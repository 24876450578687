import React, { useEffect, useState } from "react";
import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Signin from "./components/auth/Signin";
import AuthDetails from "./components/AuthDetails";

const firebaseConfig = {
  apiKey: "AIzaSyDBiO1LcHu3ng8OyXO6I23FFpfFbF3f8Bc",
  authDomain: "mmmm-2d7ea.firebaseapp.com",
  projectId: "mmmm-2d7ea",
  storageBucket: "mmmm-2d7ea.appspot.com",
  messagingSenderId: "66873696981",
  appId: "1:66873696981:web:86e78e35c44d91b172785c",
};

firebase.initializeApp(firebaseConfig);

function Letter() {
  return (
    <div className="mainLetter">
      <div>
        <h1>My Shona Baby</h1>
        <p>
          I want to start of by saying. I LOVE YOU. I LOVE YOU. I LOVE YOU SO
          MUCH. This is about to be corny as hell 🙄. But i wanted to write you
          some in a letter but like I'm not gonna see you for a bit 😞. So a
          digital letter i guess 😘. You mean the absolute world to me my baby.
          Idk where to start but the sheer happiness you bring to my life is
          something I never knew was possible!!! The way you just fit in to my
          life is just magical and that's the only way i can describe it. We
          needa tell people we're middle school sweethearts IDGAF 🤬 😤. I love
          you so much baby, I genuinely pray for you every night and i jsut know
          and believe that it's gonna workout between us. Every little thing
          about you has made me fall in love with you time and time and time and
          time and time again. Your beauty is at the top of this world, your
          maturity held us together countless amounts of times, your personality
          is to fall in love for, your kindness i look up to, your values align
          with mine, your imaan influences my soul to get on my deen harder than
          ever, your humor makes me giggle and jiggle every time 💅, and you
          just make me want to work the absolute hardest for you so I can be the
          best version of myself just for you. You're always on my mind 7 years
          since I know you, but my honeymoon phase is everytime I see you no
          matter how long it's been. You bring this joy in my heart that i can
          never forget and can never get over. I appreciate you so much and I'm
          so grateful to have you in my life. No matter what happens or how life
          turns out, please always be there for me, always be mine. It's you
          baby, it's you, it's been you, it'll never be anyone else, I love you
          so much, we will make it work, please keep praying to allah that
          everything works out for us. Because i have no doubt in my mind that
          you are the one for me. I see you being my wife, the mother of my
          children, my best friend, my therepist, my gym partner, my business
          partner, my person, and my everything.
        </p>

        <p>I LOVE YOU SO MUCH,</p>
        <p>Your Baby Baby</p>
      </div>

      <div>
        <h1>My Shona Baby</h1>
        <p>
          I LOVE YOU I LOVE YOU I LOVE YOU I LOVE YOU I LOVE YOU. I know I’m
          gonna go to sleep right now but tmr is july 19th. And that means our
          anniversary, when did we start 2019? Yeah imma count all the years,
          fuck you. So happy 5 year anniversary babyyy. That’s just the official
          but in reality it’s happy 8 years cuz you been mine since i seen you
          first hehehe. Damn baby i just love you so much like I just love you
          so much damn. I miss you every minute that you’re not around me, I
          miss you even when i see you cuz i be missing you for the next time
          imma wait and see you. You’re literally my everything and everything I
          do it’s all for you, you’re the reason i wake up every morning and
          genuinely wanna grind to become my absolute best. You make me a better
          person, you make me a happy person. You do things to my head that i
          can’t express with words. Just hugging you brings me instant and
          enormous amounts of peace that i wouldn't be able to achieve without
          your arms. I love every bit and aspect of you and of us. I love our
          dynamics. I love how we look. I love our height difference. I love how
          you love me. I love how you listen to me and respect me. I love the
          loyalty you offer me. I love the efforts you put for me. I love how
          you always manage to be there for me even on days i don’t even ask for
          your support, you just somehow sense it and get it out of me and
          comfort me. I love how we have this abstract force of love that never
          diminishes even after not seeing you for a long time. I love the idea
          of you. I love the existence of you. I love your body. I love your
          soul. I love your mind. I love your thought process. I love the way
          you carry yourself. I love the way you ARTICULATE your words. I love
          the way you hold me. I love the way you feed me. I love the way you
          keep your cool even when i have done something could’ve easily made me
          mad. I love how you could be so busy but you still think of me and
          make sure to let me know that. I love when we facetime and jus giggle
          for no reason. I love when you tell me to guess how many fingers
          you’re holding up. I love when you fake argue because you’re bored at
          the fact that we’re so pretty and perfect and dont fight. I love every
          bit of you. I can go on and on and on and on, but it’s gonna take a
          lifetime before i can finish. I just love you so much baby. Im sure
          about marrying you, i have never been more sure. I picture you as my
          one and only in the future. I only see you and you only in my future.
          I can’t wait to make unlimited memories with you. Go on vacation with
          you. Have a routine with you, run businesses with you, workout with
          you. Big balling and shot calling and running circles around these
          haters and hoes. I love you so much and happy anniversary baby, many
          many many many many many more and infinite more to come. I’m sorry i
          didn't ARTICULATE my words that well but you know you get the idea. I
          love you and happy anniversary more life.
        </p>
        <p>
          PS: I'M GONNA MAKE THIS WEBSITE WAY BETTER I PROMISE YOUUUUUUUUUUUU
        </p>
        <p>I LOVE YOUUUUUUUUUUUUUUUUUU</p>
        <p>MUAAHHHHHHHH</p>
      </div>
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      {user ? (
        <Letter />
      ) : (
        <>
          <div className="loginForm">
            <h1>My Baby's Diary</h1>
            <Signin />
          </div>
        </>
      )}
      <AuthDetails />
    </div>
  );
}

export default App;
